<template>
    <va-modal
        v-model="s_data"
        @cancel="onCancel"
        hideDefaultActions
        class="flex xs12"
        title="Ticket Data"
        size="large"
        ok-text="Ok"
    >
        <section>
          <p><b>User Name: </b>{{ s_data.user }}</p>
          <p><b>User Email: </b>{{ s_data.email }}</p>
          <p><b>User Device: </b>{{ s_data.device ? s_data.device : "N/A" }}</p>
          <br />
          <!-- NIHSS+ Ticket Details -->
          <p><b>Description: </b>{{ s_data.description }}</p>
          <p><b>User Message: </b>{{ s_data.message }}</p>
          <p><b>User UID: </b><router-link :to="'/users/edituser/' + s_data.userID">{{ s_data.userID }}</router-link></p>
          <br />
          <!-- Ticket Area -->
          <p><b>Ticket ID: </b>{{s_data.id}} </p>
          <p><b>Ticket Categories: </b>{{s_data.categories}} </p>
          <p><b>Status:</b> {{ s_data.fullStatus }}  </p>
          <p><b>Creation Date:</b> {{ s_data.creationDate }} (YYYY/MM/DD)</p>
          <p><b>Last Update:</b> {{ s_data.updatedAt }} (YYYY/MM/DD)</p>
        </section>
        <section>
            <div class="pt-2">
             
                <va-button
                    v-show="showReply"
                    slot="actions"
                    @click="triggerFunction('SendMessage', {id: s_data.id, email: s_data.email})"
                    color="primary"
                    class="mr-2"
                > Reply User </va-button>
                <va-button
                    slot="actions"
                    @click="triggerFunction('EditStatus', {id: s_data.id, status: s_data.status})"
                    color="primary"
                    class="mr-2"
                > Change Status </va-button>
                <va-button
                    slot="actions"
                    @click="triggerFunction('attachAdmin', {id: s_data.id, adminID: s_data.adminID})"
                    color="primary"
                    class="mr-2"
                > Attach Admin </va-button>
                <va-button
                    slot="actions"
                    @click="triggerFunction('viewAvailableNotes', s_data)"
                    color="primary"
                    class="mr-2"
                > Notes </va-button>
                <va-button
                    slot="actions"
                    @click="onCancel"
                    color="gray"
                    class="mr-2"
                > Close </va-button>
            </div>
        </section>
    </va-modal>
</template>

<script>
export default {
    name: 'NIHSSTicket',
    props: {
        data: {
            required: true,
        },
        showReply: {
            type: Boolean,
            default: true,
        },
    },
    
    data() {
        return {
            s_data: false,
        }
    },
    methods: {
        onCancel() {
            this.$emit('cancel');
        },
        triggerFunction(name, data){
            this.$emit(name, data)
        }
    },
    watch: {
        data: {
            handler() {
                this.s_data = this.data;
            },
            immediate: true,
        },
    },
}
</script>