<template>
  <div>
    <div class="flex md12">
      <va-card :title="'Your NIHSS+ Tickets'">
        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              :value="term"
              :placeholder="'Search Users/Tickets'"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex xs12 md3">
            <va-select
              id="filterBy"
              v-model="filterBy"
              label="Filter by"
              :options="filterOptions"
              no-clear
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-select>
          </div>
          <div class="flex xs12 md3">
            <va-select
              id="sortBy"
              v-model="sortBy"
              label="Sort by"
              :options="sortByOptions"
              no-clear
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-select>
          </div>
          <div class="flex xs12 md3 md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
        <!-- Modals -->
        <NIHSSTicket
          @cancel="closePopup('nihss')"
          @viewAvailableNotes="viewAvailableNotes"
          @SendMessage="SendMessage"
          @EditStatus="EditStatus"
          @attachAdmin="attachAdmin"
          :data="CurrentNihssTicketPopup"
        />
        <Notes
          @deleteCurrentNote="deleteCurrentNote"
          @cancel="closePopup('notes')"
          :NoteFilteredData="NoteFilteredData"
          @CreateNewNote="CreateNewNote"
          :data="showNotesModal"
        />
        <TicketData
          @cancel="closePopup('tickets')"
          :ticketsFilteredData="ticketsFilteredData"
          :data="showMediumModal"
        />
        <MessageView
          @cancel="closePopup('messages')"
          @newMessage="newMessage"
          :messages="messageHistory"
          :ticketData="currentTicket"
          :data="showMessageModal"
        />
        <!-- Modals -->
        <va-data-table
          :fields="fields"
          :data="filteredData"
          @row-clicked="showTicketData"
          :per-page="parseInt(perPage)"
          :loading="loading"
          clickable
          hoverable
        >
          <template slot="email" slot-scope="props">
            <span>{{ props.rowData.email ? props.rowData.email : "N/A" }}</span>
          </template>
          <template slot="actions" slot-scope="props">
            <div class="flex row" @click="ticketPopup()">
              <va-button
                @click="viewAvailableNotes(props.rowData)"
                title="View Available Notes"
                icon="fa fa-paperclip"
              />
              <va-button
                @click="
                  attachAdmin({
                    id: props.rowData.id,
                    adminID: props.rowData.adminID
                  })
                "
                title="Attach Admin"
                icon="fa fa-link"
              />
              <va-button
                @click="
                  SendMessage({
                    id: props.rowData.id,
                    email: props.rowData.email
                  })
                "
                title="Send Message (Email)"
                icon="fa fa-envelope"
              />
              <va-button
                @click="
                  ViewMessages({
                    id: props.rowData.id,
                    email: props.rowData.email,
                    adminID: props.rowData.adminID,
                    userID: props.rowData.userID,
                    message: props.rowData.message,
                    creationDate: props.rowData.timestamp
                  })
                "
                title="View Messages"
                icon="fa fa-comments"
              />
              <va-button
                @click="
                  EditStatus({
                    id: props.rowData.id,
                    status: props.rowData.status
                  })
                "
                title="Edit Status"
                icon="fa fa-edit"
              />
            </div>
          </template>
        </va-data-table>
      </va-card>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import moment from "moment";
import firebase from "firebase";
import axios from "@/scripts/interceptor.js";
import NIHSSTicket from "./modals/NIHSSTicket.vue";
import TicketData from "./modals/TicketData.vue";
import EvaluationsTicket from "./modals/EvaluationsTicket.vue";
import Notes from "./modals/Notes.vue";
import TicketCatcher from "../../class/TicketCatcher";
import MessageView from "./modals/MessageView.vue";
export default {
  data() {
    return {
      showTicketPopup: true,
      loading: true,
      regradeLoading: false,
      currentTicket: false,
      CurrentNihssTicketPopup: false,
      selectedReply: false,
      showMediumModal: false,
      showNotesModal: false,
      showMessageModal: false,
      term: null,
      categories: [],
      admins: [],
      emailErrors: [],
      tickets: [],
      perPage: "10",
      perPageOptions: ["10", "50", "100", "250"],
      availableTests: [],
      ticketLabels: [],
      messageHistory: [],
      noteLabels: [],
      email: "",
      filterBy: "All",
      sortBy: "All",
      filterOptions: ["All", "Pending", "In Progress", "Resolved"],
      sortByOptions: [ "All", "Today", "1 day", "3 days", "7 days", "30 days"],
    };
  },
  components: {
    NIHSSTicket,
    EvaluationsTicket,
    TicketData,
    Notes,
    MessageView
  },
  props: {
    parent_uid: {
      type: String,
    },
  },
  async created() {
    let uid = firebase.auth().currentUser.uid;

    let ticketsQuery;
    if(this.parent_uid){
        ticketsQuery = this.$db.collection('evalTickets')
            .where('evalID', '==', false)
            .where('userID', '==', this.parent_uid);
    } else {
        ticketsQuery = this.$db.collection('evalTickets')
            .where('adminID', '==', uid)
            .where('evalID', '==', false);
    }
    const [credentials, states, categories, tickets] = await Promise.all([
        this.$db.collection('credentials').get(),
        this.$db.collection('states').get(),
        this.$db.collection('evalCategories').get(),
        ticketsQuery.get()
    ]);
    
    const credentialData = credentials.docs.map((doc) => doc.data());
    const stateData = states.docs.map((doc) => doc.data());

    categories.forEach(category => {
      this.categories.push({
        id: category.id,
        ...category.data()
      });
    });

    await Promise.all(
      tickets.docs.map(async ticket => {
        const data = ticket.data();
        let user = {};
        let userData = {};

        if (data.userID) {
          user = await this.$db
            .collection("users")
            .doc(data.userID)
            .get();
          userData = user.data();
        } else {
          userData = {
            displayName: data.userName,
            email: data?.userEmail,
            device: data?.userDevice ? data.userDevice : "N/A",
            stateCode: "N/A",
            credentialCode: "N/A"
          };
        }
        const displayName = userData?.displayName
          ? userData.displayName
          : "N/A";
        const name = displayName
          .split(" ")
          .map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");

        if (data.status) {
          switch (data.status) {
            case "pending":
              data.fullStatus = "Pending";
              break;
            case "inprogress":
              data.fullStatus = "In Progress";
              break;
            case "resolved":
              data.fullStatus = "Resolved";
              break;
            default:
              data.fullStatus = "Pending";
              break;
          }
        } else {
          data.status = "pending";
          data.fullStatus = "Pending";
        }

        let updatedAt = data.updatedAt ? data.updatedAt : data.creationDate;

        let stateName = "";
        let credentialName = "";

        try {
          stateName = stateData.find(
            state => state.abbreviation == userData.stateCode
          ).name;
          credentialName =
            credentialData.find(
              credential => credential.value == userData.credentialCode
            )?.displayName || "N/A";
        } catch (error) {
          stateName = "N/A";
          credentialName = "N/A";
        }
        let rawCategory = data.category;
          if(!data.category){
            if(data.evalID){
              rawCategory = 'evaluation';
            }else{
              rawCategory = 'Not classified';
            }
        }
        this.tickets.push({
          id: ticket.id,
          ticketType: !data.examID && !data.evalID ? "nihss" : "evaluation",
          description: data.description,
          message: data.message,
          device: userData?.device,
          userID: data.userID,
          adminID: data.adminID,
          stateCode: stateName + " - " + userData?.stateCode,
          credentialCode: credentialName + " - " + userData?.credentialCode,
          category: this.formatCategory(data.category, data.evalID),
          rawCategory: rawCategory,
          examID: data.examID,
          answers: data.answers,
          user: name,
          email: userData?.email,
          evaluation: data.evalID == false ? data.description : data.evalID,
          notes: data.notes,
          fullStatus: data.fullStatus,
          status: data.status,
          tests: "example",
          timestamp: data.creationDate,
          creationDate: moment(data.creationDate.toDate()).format(
            "MM/DD/YYYY - HH:mm"
          ),
          claimedDate: data?.claimedDate ? moment(data.claimedDate.toDate()).format('MM/DD/YYYY - HH:mm') : 'N/A',
          claimedDateTimestamp: data?.claimedDate ? data.claimedDate : data.creationDate,
          updatedAt: moment(updatedAt.toDate()).format("MM/DD/YYYY - HH:mm")
        });
      })
    );

    this.loading = false;

    if (this.$route.params.ticket_id) {
      let ticket = this.$route.params.ticket_id;
      let item = this.tickets.find(item => item.id === ticket);

      if (!item) {
        this.$swal.fire({
          title: "Oops! Ticket not found!",
          text:
            "The ticket you are trying to access does not exist or has been deleted, make sure you are using the correct link.",
          icon: "warning",
          confirmButtonText: "OK"
        });
        return;
      }

      this.ViewMessages(item);
    }
  },
  computed: {
    fields() {
      return [
        {
          name: 'user',
          title: 'Name',
          sortField: 'user',
          width: '12.5%',
        },
        {
          name: 'email',
          title: 'User Email',
          sortField: 'email',
          width: '12.5%',
        }, {
          name: 'evaluation',
          title: 'Evaluation ID/Details',
          sortField: 'evaluation',
          width: '10%',
        },
        {
          name: 'category',
          title: 'Category',
          sortField: 'category',
          width: '23%',
        },
        {
          name: 'fullStatus',
          title: 'Ticket Status',
          sortField: 'fullStatus',
          width: '8%',
        },
        {
          name: 'creationDate',
          title: 'Created At',
          sortField: 'creationDate',
          width: '10%',
        },
        {
          name: "__slot:actions",
          title: "Actions",
          width: "20%"
        }
      ];
    },
    filteredData() {
      if (!this.term || this.term.length < 1) {
        let response = []
        //Use filter by dropdown
        if(this.filterBy !== "All") {
          let tempD = this.tickets.filter(item => {
            return item.status.toLowerCase() === this.filterBy.toLowerCase();
          });
          response = tempD.sort((a, b) => {
            return b.timestamp.seconds - a.timestamp.seconds;
          });
          return this.applySortBy(response);
        }
        this.tickets.sort((a, b) => {
          return b.timestamp.seconds - a.timestamp.seconds;
        });
        response = this.tickets;
        return this.applySortBy(response);
      }
      // filter by user
      const filteredByUser = this.tickets.filter(item => {
        let returnUser = false;
        if (item.user) {
          if (item.user.toLowerCase().indexOf(this.term.toLowerCase()) !== -1){
            if(this.filterBy !== "All" && item.status.toLowerCase() === this.filterBy.toLowerCase()){
              returnUser = true;
            } else if(this.filterBy === "All"){
              returnUser = true;
            }
          }
        }
        return returnUser;
      });
      // sort filtered results by timestamp.seconds desc
      const sortedFilteredData = filteredByUser.sort((a, b) => {
        return b.timestamp.seconds - a.timestamp.seconds;
      });
      return this.applySortBy(sortedFilteredData);
    },
    NoteFilteredData() {
      if (!this.term) {
        //order by realDate
        this.noteLabels.sort((a, b) => {
          return b.realDate - a.realDate;
        });
        return this.noteLabels;
      }
      return this.tickets.filter(exam => {
        return exam.user.toLowerCase().includes(this.term.toLowerCase());
      });
    },
    ticketsFilteredData() {
      if (!this.term) {
        return this.ticketLabels;
      }
      return this.tickets.filter(exam => {
        return exam.user.toLowerCase().includes(this.term.toLowerCase());
      });
    }
  },
  methods: {
      formatCategory(category, evalID) {
      const categoryEnum = {
          "accountIssue": "I need to update my name and/or my email address",
          "certificateIssue": "I need a copy of my certificate(s).",
          "deleteAccount": "I would like to delete my account",
          "applicationIssue": "I am having issues with the application",
          "other": "Something else...",
          "evaluation": "I need help with an evaluation",
        };
        //if category is null or empty return na
        if(!category){
          if(evalID){
            return categoryEnum["evaluation"]
          }
          return "Not classified";
        }
        return categoryEnum[category] ? categoryEnum[category] : categoryEnum["other"]
    },
    applySortBy(data) {
      let response = data
      if(this.sortBy !== "All") {
        let tempD = data.filter(item => {
          let date = new Date();
          let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          let yesterday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
          let lastWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
          let lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
          let itemDate = new Date(item.claimedDateTimestamp.seconds * 1000);
          if (this.sortBy === "Today") {
            return itemDate >= today;
          } else if(this.sortBy === "1 day") {
            return itemDate >= yesterday;
          } else if(this.sortBy === "3 days") {
            return itemDate >= yesterday;
          } else if(this.sortBy === "7 days") {
            return itemDate >= lastWeek;
          } else if(this.sortBy === "30 days") {
            return itemDate >= lastMonth;
          }
        });
        response = tempD.sort((a, b) => {
          return b.timestamp.seconds - a.timestamp.seconds;
        });
      }
      return response;
    },
    ticketPopup(data) {
      this.showTicketPopup = false;
    },
    showTicketData(data) {
      //show swal with html content of ticket data
      if (this.showTicketPopup) {
        switch (data.ticketType) {
          case "nihss":
            this.CurrentNihssTicketPopup = data;
            break;
        }
      } else {
        this.showTicketPopup = true;
      }
    },
    CreateNewNote() {
      //get id of logged in user ujsing firebase
      //get id of current ticket
      let user = firebase.auth().currentUser;
      let userID = user.uid;

      //open swal to user insert note, then use firebase to save in "notes" array from current Ticket
      this.$swal
        .fire({
          title: "Create a new note",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Save",
          showLoaderOnConfirm: true,
          preConfirm: note => {
            return note;
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then(result => {
          if (result.value) {
            this.$swal.fire({
              title: "Note created!",
              text: "Your note has been created",
              type: "success",
              confirmButtonText: "Ok"
            });
            //push to this.currentTicket.notes if exists, else create array
            this.currentTicket.notes = this.currentTicket.notes
              ? this.currentTicket.notes
              : [];
            //push new note to array
            this.currentTicket.notes.push({
              note: result.value,
              adminID: userID,
              creationDate: new Date()
            });
            this.$swal.fire({
              title: "Saving note...",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: async () => {
                this.$swal.showLoading();
                await firebase
                  .firestore()
                  .collection("evalTickets")
                  .doc(this.currentTicket.id)
                  .update({
                    notes: this.currentTicket.notes
                  });
                this.loadNotes(this.currentTicket);
                this.$swal.close();
              }
            });
          }
        });
    },
    deleteCurrentNote(data) {
      //delete item with index data.id  from this.currentTicket.notes
      this.currentTicket.notes.splice(data.id, 1);
      this.$swal.fire({
        title: "Deleting note...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: async () => {
          this.$swal.showLoading();
          await firebase
            .firestore()
            .collection("evalTickets")
            .doc(this.currentTicket.id)
            .update({
              notes: this.currentTicket.notes
            });
          this.loadNotes(this.currentTicket);
          this.$swal.close();
        }
      });
    },
    closePopup(modal) {
      switch (modal) {
        case "nihss":
          this.CurrentNihssTicketPopup = false;
          break;
        case "evaluation":
          this.CurrentEvaluationTicketPopup = false;
          break;
        case "notes":
          this.showNotesModal = false;
          this.currentTicket = false;
          break;
        case "tickets":
          this.showMediumModal = false;
        case "messages":
          this.showMessageModal = false;
          break;
      }
    },
    loadNotes(data) {
      this.noteLabels = [];
      if (data.notes) {
        data.notes.forEach((note, id) => {
          let formatedDate = note.creationDate;
          //check if note.creationDate is a date or a string
          try {
            formatedDate = moment(formatedDate.toDate()).format(
              "MM/DD/YYYY - HH:mm"
            );
          } catch (error) {
            formatedDate = moment(formatedDate).format("MM/DD/YYYY - HH:mm");
          }
          this.noteLabels.push({
            id: id,
            adminID: note.adminID,
            note: note.note,
            realDate: note.creationDate,
            date: formatedDate
          });
        });
      }
    },
    viewAvailableNotes(data) {
      this.showNotesModal = true;
      //foreach content in data.answers inser into ticketLabels
      this.currentTicket = data;
      this.loadNotes(this.currentTicket);
    },
    async ShowAttachModal(id, currentAdmin) {
      //fin d item in this.tickets that has id = id
      let admins = this.admins;
      const inputOptions = admins.reduce((o, i) => {
        o[i.key] = i.value;
        return o;
      }, {});
      let item = this.tickets.find(item => {
        return item.id === id;
      });
      this.$swal
        .fire({
          title: "Attach Admin",
          input: "select",
          inputOptions: inputOptions,
          inputValue: currentAdmin,
          inputPlaceholder: "Select an Admin",
          confirmButtonText: "Save Changes",
          showCancelButton: true,
          inputValidator: value => {
            return new Promise(resolve => {
              if (!value) {
                resolve("You need to select an admin");
              } else {
                resolve();
              }
            });
          }
        })
        .then(result => {
          if (result.value) {
            //swal with loading, close after promise
            this.$swal.fire({
              title: "Saving Changes",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: () => {
                let that = this;
                this.$swal.showLoading();
                firebase
                  .firestore()
                  .collection("evalTickets")
                  .doc(id)
                  .update({
                    adminID: result.value,
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                  })
                  .then(() => {
                    this.$swal
                      .fire({
                        title: "Success!",
                        text: "Admin Assigned has been updated",
                        icon: "success",
                        confirmButtonText: "OK"
                      })
                      .then(() => {
                        //remove item from list
                        this.tickets = this.tickets.filter(item => {
                          return item.id !== id;
                        });
                        this.CurrentTicketPopup = false;
                        //stop loading
                        that.$swal.hideLoading();
                      });
                  })
                  .catch(error => {
                    this.$swal.fire({
                      title: "Error!",
                      text: error.message,
                      icon: "error",
                      confirmButtonText: "OK"
                    });
                  });
              }
            });
          }
        });
    },
    async attachAdmin(data) {
      //Only fetch admins if they haven't been fetched yet
      if (this.admins.length < 1) {
        let that = this;
        this.$swal
          .fire({
            title: "Loading Admins",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: async () => {
              this.$swal.showLoading();
              //fetch from firestore in collection users, the ones that has isAdmin = true
              await firebase
                .firestore()
                .collection("users")
                .where("isAdmin", "==", true)
                .get()
                .then(result => {
                  let data = result.docs.map(doc => {
                    let docData = doc.data();
                    //transform displayName in format "Lastname, Firstname"
                    let name = docData?.displayName
                      ? docData.displayName.split(" ")
                      : ["", ""];
                    let lastName = name.pop();
                    let firstName = name.join(" ");
                    docData.displayName = lastName + ", " + firstName;
                    //capitalize first letter of each word
                    docData.displayName = docData?.displayName
                      ? docData.displayName.replace(/\w\S*/g, function(txt) {
                          return (
                            txt.charAt(0).toUpperCase() +
                            txt.substr(1).toLowerCase()
                          );
                        })
                      : "";
                    return {
                      key: doc.id,
                      value: docData.displayName,
                      lastName: lastName,
                      firstName: firstName
                    };
                  });
                  //alphabetical order by firstName key, if is null then by lastName
                  data.sort((a, b) => {
                    if (a.firstName == "") {
                      if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                        return -1;
                      }
                      if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    } else {
                      if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                        return -1;
                      }
                      if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    }
                  });
                  this.admins = data;
                })
                .catch(error => {
                  that.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                      "Something went wrong while trying to fetch the admins! Please try again later."
                  });
                });
              this.$swal.close();
            }
          })
          .then(result => {
            this.ShowAttachModal(data.id, data.adminID);
          });
      } else {
        this.ShowAttachModal(data.id, data.adminID);
      }
    },
    EditStatus(data) {
      //open swal with select input and let first option be the current status
      let inputOptions = {
        pending: "Pending",
        inprogress: "In Progress",
        resolved: "Resolved"
      };
      //find item in this.tickets that has id = id
      let item = this.tickets.find(item => {
        return item.id === data.id;
      });
      this.$swal
        .fire({
          title: "Edit Status",
          input: "select",
          inputOptions: inputOptions,
          inputValue: data.status,
          inputPlaceholder: "Select a status",
          confirmButtonText: "Save Changes",
          showCancelButton: true,
          inputValidator: value => {
            return new Promise(resolve => {
              if (value === "pending") {
                resolve();
              } else if (value === "inprogress") {
                resolve();
              } else if (value === "resolved") {
                resolve();
              } else {
                resolve("You need to select something!");
              }
            });
          }
        })
        .then(result => {
          if (result.value) {
            //swal with loading, close after promise
            this.$swal.fire({
              title: "Saving Changes",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: () => {
                let that = this;
                this.$swal.showLoading();
                //save changes to firebase
                firebase
                  .firestore()
                  .collection("evalTickets")
                  .doc(data.id)
                  .update({
                    status: result.value,
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                  })
                  .then(() => {
                    this.$swal
                      .fire({
                        title: "Success!",
                        text: "Status has been updated",
                        icon: "success",
                        confirmButtonText: "OK"
                      })
                      .then(() => {
                        item.status = result.value;
                        item.fullStatus = inputOptions[result.value];
                        let date = new Date();
                        item.updatedAt =
                          date.getFullYear() +
                          "/" +
                          (date.getMonth() + 1) +
                          "/" +
                          date.getDate();
                        //stop loading
                        that.$swal.hideLoading();
                      });
                  })
                  .catch(error => {
                    this.$swal.fire({
                      title: "Error!",
                      text: error.message,
                      icon: "error",
                      confirmButtonText: "OK"
                    });
                  });
              }
            });
          }
        });
    },
    async ViewMessages(data) {
      this.showMessageModal = true;
      this.currentTicket = data;
      //add data.message as the first user message
      this.loadMessages(data?.id);
    },
    async newMessage(event) {
      //add message to the current messageHistory
      this.messageHistory.push({
        message: event.message,
        senderUID: firebase.auth().currentUser.uid,
        createdAt: firebase.firestore.Timestamp.now()
      });
    },
    async loadMessages(ticketID) {
      this.messageHistory = [];
      if (ticketID) {
        const { messages } = await new Promise((resolve, reject) => {
          axios
            .get("/api/notifications/messages/history/" + ticketID)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
        });
        //First Message Push (Always the user message)
        this.messageHistory.push({
          message: this.currentTicket.message,
          senderUID: this.currentTicket.userID,
          createdAt: this.currentTicket.creationDate
        });
        this.messageHistory = this.messageHistory.concat(messages);
      }
    },
    async SendMessage(data) {
      let htmlSelectOptions = "";
      //list items from evalTicketReplies collection
      this.$swal.fire({
        title: "Loading Models",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: async () => {
          this.$swal.showLoading();
          //fetch from firestore in collection users, the ones that has isAdmin = true
          await firebase
            .firestore()
            .collection("evalTicketReplies")
            .get()
            .then(result => {
              let data = result.docs.map(doc => {
                return {
                  key: doc.id,
                  value: doc.data().title,
                  message: doc.data().message,
                  subject: doc.data().subject
                };
              });
              this.replies = data;
            });
          this.replies.unshift({
            key: "default",
            value: "Select a Model (Default)"
          });
          const inputOptions = this.replies.reduce((o, i) => {
            o[i.key] = i.value;
            return o;
          }, {});
          for (const [key, value] of Object.entries(inputOptions)) {
            htmlSelectOptions += `<option label="${value}" value="${key}">${value}</option>`;
          }
          TicketCatcher.reply(htmlSelectOptions, data, this.replies).then(
            () => {
              this.CurrentNihssTicketPopup = false;
            }
          );
        }
      });
    },
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100)
  }
};
</script>

<style>
.va-data-table {
  overflow: hidden !important;
}

@media (max-width: 768px) {
  .va-data-table {
    overflow: visible !important;
  }
}
</style>
