<template>
    <va-modal              
          v-model="s_data"
          @cancel="onCancel"
          hideDefaultActions
          class="flex xs12"
          title="Ticket Data"
          size="large"
          ok-text="Ok">
        <section>  
          <div>
            <b>Attached Admin ID: </b> 
            <span v-if="s_data.adminID != false">
              <router-link title="Click to see more" :to="'/users/edituser/' + s_data.adminID">{{s_data.adminID }}</router-link>
            </span>
            <span v-else>
              Not Assigned
            </span>
          </div>
          <div>
            <b>Evaluation ID:</b>
            <router-link title="Click to see more" :to="'/editor/eval/' + s_data.evaluation">{{s_data.evaluation}}</router-link>
          </div>
          <p><b>Exam ID:</b> {{s_data.examID}}</p>
          <br/>
          <div>
            <b>User ID: </b> 
            <router-link title="Click to see more" :to="'/users/edituser/' + s_data.userID" >{{s_data.userID}}</router-link>
          </div>
          <p><b>User Name: </b>{{ s_data.user }}</p>
          <p><b>User Email: </b>{{ s_data.email }}</p>
          <p><b>User State: </b>{{ s_data.stateCode }}</p>
          <p><b>User Credential: </b>{{ s_data.credentialCode }}</p>
          <br/>
          <!-- Ticket Area -->
          <p><b>Ticket ID: </b>{{s_data.id}} </p>
          <p><b>Ticket Categories: </b>{{s_data.categories}} </p>
          <p><b>Status:</b> {{ s_data.fullStatus }}  </p>
          <p><b>Creation Date:</b> {{ s_data.creationDate }} (YYYY/MM/DD)</p>
          <p><b>Last Update:</b> {{ s_data.updatedAt }} (YYYY/MM/DD)</p>
        </section>
        <div class="pt-2">
            <va-button
                slot="actions"
                @click="triggerFunction('viewQuestionsTriggered',s_data)"
                color="primary"
                class="mr-2"
            > Feedback </va-button>
            <va-button
                slot="actions"
                @click="triggerFunction('viewAvailableNotes', s_data)"
                color="primary"
                class="mr-2"
            > Notes </va-button>
            <va-button
                v-show="showReply"
                slot="actions"
                @click="triggerFunction('SendMessage', {id: s_data.id, email: s_data.email})"
                color="primary"
                class="mr-2"
            > Reply Ticket </va-button>
            <va-button
                slot="actions"
                @click="triggerFunction('EditStatus', {id: s_data.id, status: s_data.status})"
                color="primary"
                class="mr-2"
            > Change Status </va-button>
            <va-button
                slot="actions"
                @click="triggerFunction('viewEvaluationData', {evaluation: s_data.evaluation, userID: s_data.userID})"
                color="primary"
                class="mr-2"
            > Evaluation  </va-button>
            <va-button
                slot="actions"
                @click="triggerFunction('attachAdmin', {id: s_data.id, adminID: s_data.adminID})"
                color="primary"
                class="mr-2"
            > Attach Admin </va-button>
            <va-button
                slot="actions"
                @click="onCancel"
                color="gray"
                class="mr-2"
            > Close </va-button>
        </div>
    </va-modal>
</template>
<script>
export default {
    name: 'EvaluationsTicket',
    props: {
        data: {
            required: true,
        },
        showReply: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            s_data: false,
        }
    },
    methods: {
        onCancel() {
            this.$emit('cancel');
        },
        triggerFunction(name, data){
            this.$emit(name, data)
        }
    },
    watch: {
        data: {
            handler() {
                this.s_data = this.data;
            },
            immediate: true,
        },
    },
}
</script>