<template>
     <va-modal
        v-model="s_data"
        @cancel="onCancel"
        hideDefaultActions
        class="flex xs12"
        title="Available Notes"
        size="large"
        ok-text="Ok">
        <va-data-table
            :fields="NoteFields"
            :data="NoteFilteredData"
            :per-page="5"
        >
        <template slot="actions" slot-scope="props">
        <va-button
            @click="deleteCurrentNote(props.rowData)"
            color="danger"
            icon="fa fa-trash"
            class="mr-2"
        />
        </template>
        </va-data-table>


        <div class="pt-2">
        <va-button
            slot="actions"
            @click="CreateNewNote"
            color="primary"
            class="mr-2"
        > Create New</va-button>
        <va-button
            slot="actions"
            @click="onCancel"
            color="primary"
            class="mr-2"
        > Close </va-button>
        </div>
    </va-modal>
</template>
<script>
export default {
    name: 'Notes',
    props: {
        data: {
            required: true,
        },
        NoteFilteredData: {
            required: true,
        },
    },
    data() {
        return {
            s_data: false,
        }
    },
    computed: {
        NoteFields(){
            return [{
                name: 'adminID',
                title: 'AdminID',
                sortField: 'adminID',
                width: '30%',
            },
            {
                name: 'note',
                title: 'Note',
                sortField: 'note',
                width: '50%',
            }, 
            {
                name: 'date',
                title: 'Created At',
                sortField: 'date',
                width: '20%',
            }, 
            {
                name: '__slot:actions',
                title: 'Actions',
                sortField: 'actions',
                width: '10%',
            }, 
            ];
        },
    },
    methods: {
        onCancel() {
            this.$emit('cancel');
        },
        deleteCurrentNote: function(data){
            this.$emit('deleteCurrentNote', data)
        },
        CreateNewNote: function(){
            this.$emit('CreateNewNote')
        },
    },
    watch: {
        data: {
            handler() {
                this.s_data = this.data;
            },
            immediate: true,
        },
    },
}
</script>